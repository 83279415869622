import React from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';
import StyledPillButton, { StyledPillButtonGroup } from '../primitives/styled-pill-button';
import Row from '../primitives/grid/row';
import Col from '../primitives/grid/col';

const Menu = ({ hideSelector }: { hideSelector?: boolean }): JSX.Element => {
    const {
        allWpTag: { nodes: tags },
    } = useStaticQuery(graphql`
        {
            allWpTag(sort: { name: ASC }) {
                nodes {
                    link
                    name
                    projectCategoryInfo {
                        column1
                        column2
                    }
                }
            }
        }
    `);

    const allTag = 'All Sectors';

    const { pathname, search } = useLocation();

    const view = search.includes('list') ? 'List' : 'Gallery';

    const filteredTagsWithAllData = (() => {
        const start = tags.reduce((acc: any, val: any) => {
            acc[val.name] = val;
            acc[val.name].link = val.link.replace('news/tag', 'projects');
            return acc;
        }, {});

        return [
            {
                name: allTag,
                link: '/projects/',
            },
            ...Object.values(start),
        ];
    })();

    return (
        <div
            css={{
                width: '100%',
                height: 'auto',
                display: 'flex',
                backgroundColor: 'var(--color-shades-white)',
                alignItems: 'flex-start',
                flexDirection: 'column',
                paddingTop: '10px',
                paddingBottom: '10px',
                zIndex: 1,
                paddingLeft: hideSelector ? '0' : 'calc(176px + 30px)',
                '@media (max-width: 1200px)': {
                    paddingLeft: '0',
                },
            }}
        >
            <Row css={{ position: 'relative' }}>
                <Col
                    breakpoints={{ dt: { span: 9, leftGap: hideSelector ? 'full' : 'none' }, tb: { span: 12 }, mb: { start: 0, span: 12 } }}
                    css={{
                        gap: '10px',
                        width: '100%',
                        height: 'auto',
                        gridRow: '1',
                    }}
                >
                    <div
                        css={{ display: 'flex' }}
                    >
                        <div
                            css={{
                                color: 'var(--color-primary-pine)',
                                marginRight: '24px',
                                '@media (max-width: 767px)': {
                                    // paddingLeft: '0px',
                                },
                                fontSize: '14px',
                                lineHeight: '20px',
                                marginTop: '3px',
                            }}
                        >
                            {hideSelector ? 'Sectors' : 'Filter'}
                        </div>
                        <StyledPillButtonGroup>
                            {filteredTagsWithAllData.map(({ name, link }) => (
                                <StyledPillButton
                                    key={link}
                                    to={link}
                                    data-active={pathname === link}
                                >
                                    {name}
                                </StyledPillButton>
                            ))}
                        </StyledPillButtonGroup>
                    </div>
                </Col>
                {!hideSelector && (
                    <Col
                        breakpoints={{ dt: { span: 3, start: 9, align: 'end' }, tb: { span: 12 }, mb: { start: 0, span: 12 } }}
                        css={{
                            gridRow: '1',
                            '@media (max-width: 1200px)': {
                                gridRow: '2',
                                marginTop: '28px',
                                // position: 'unset',
                            },
                        }}
                    >
                        <div
                            css={{
                                width: 'fit-content',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                // marginRight: '75px',
                                '@media (max-width: 1200px)': {
                                    gridColumn: 'span 12',
                                    justifyContent: 'space-between',
                                    width: '100%',

                                },
                                '@media (max-width: 767px)': {
                                    // paddingLeft: '0px',
                                },
                            }}
                        >
                            <button
                                type="button"
                                css={{
                                    color: 'var(--color-primary-pine)',
                                    opacity: view === 'Gallery' ? '1' : '0.3',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    paddingRight: '16px',
                                    gridRow: '1',
                                    gridColumn: '1/2',
                                }}
                                onClick={() => {
                                    navigate(`${pathname}?view=gallery`);
                                }}
                            >
                                <span>Gallery View</span>
                            </button>
                            <button
                                type="button"
                                css={{
                                    color: 'var(--color-primary-pine)',
                                    opacity: view === 'List' ? '1' : '0.3',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    gridRow: '1',
                                    gridColumn: '3/4',
                                    // eslint-disable-next-line @typescript-eslint/indent
                                }}
                                onClick={() => {
                                    navigate(`${pathname}?view=list`);
                                }}
                            >
                                <span>List View</span>
                            </button>

                        </div>
                    </Col>
                )}
            </Row>
        </div>
    );
};
export default Menu;
